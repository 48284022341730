import React from "react";
import moment from "moment";

const InvoiceMobile = ({ invoice }) => {
  var timeZoneName = moment.tz.guess();
  return (
    <div
      style={{
        fontSize: "9.5px",
        paddingTop: "30px",
        paddingLeft: "60px",
        paddingRight: "60px",
        lineHeight: 1.5,
        display: "flex",
        flexDirection: "column",
        fontFamily: "Roboto, sans-serif",
        width: "670px",
        height: "835px",
      }}
    >
      <div
        style={{
          backgroundColor: "#293d68",
          height: "76px",
          width: "14px",
          marginTop: "-30px",
          marginLeft: "-60px",
          transformOrigin: "left bottom",
        }}
      ></div>
      <div
        style={{
          backgroundColor: "#e21e23",
          height: "60px",
          width: "14px",
          marginLeft: "-60px",
          transformOrigin: "left bottom",
        }}
      ></div>
      <div
        style={{
          backgroundColor: "#293d68",
          height: "707px",
          width: "14px",
          marginTop: "-2px",
          marginLeft: "-60px",
          transformOrigin: "right top",
        }}
      ></div>
      <div
        style={{
          marginTop: "-807px",
          marginLeft: "-25px",
          padding: "0",
          flexDirection: "column",
          width: "500px",
        }}
      >
        <div style={{ fontWeight: "900" }}>
          {invoice.data.paymentMethod === "idfc" ||
          invoice.data.paymentMethod === "axis"
            ? "EMEDISKILL INSTITUTE OF SCIENCE & TECHNOLOGY PRIVATE LIMITED"
            : "eMediSkill Services LLP"}

          {/* eMediSkill Services LLP */}
        </div>
        <div style={{ fontWeight: "900" }}>
          Aristomisr, F-06, Plot No. 42, Sr. No 588/2A, Burhani Colony, Market
          Yard, Pune - 411037
        </div>
        {/* <div><span style={{ color: '#7f7f80', fontSize: '9px' }}>GSTIN</span>- 27AAJFE5493L1ZT</div>
            <div><span style={{ color: '#7f7f80', fontSize: '9px' }}>SAC CODE</span>- 999295</div> */}
        <img
          style={{
            width: "113px",
            height: "27px",
            marginTop: "-30px",
            marginLeft: "410px",
          }}
          src="/static/media/headerLogo.588154a9068d8ae65e2c.png"
          alt="Logo"
        />
        <span style={{ marginTop: "30px", color: "#252525" }}>
          Invoice{" "}
          <span style={{ fontSize: "10px" }}>{invoice.data.invoiceId}</span>
        </span>
        <img
          style={{
            width: "150px",
            height: "30px",
            marginTop: "-12px",
            marginLeft: "75px",
          }}
          src=""
          alt=""
        />
      </div>
      <div style={{ marginTop: "5px", marginLeft: "-25px", padding: "0" }}>
        {invoice.data.firstName && invoice.data.lastName && (
          <>
            <div
              style={{ color: "#7f7f7f", fontSize: "12px", fontWeight: "700" }}
            >
              BILLING TO
            </div>
            <div style={{ fontSize: "12px", fontWeight: "600" }}>{`${
              invoice.data.firstName ? invoice.data.firstName : ""
            } ${invoice.data.lastName ? invoice.data.lastName : ""}`}</div>
            <div
              style={{
                borderBottom: "0.5pt solid #dddedd",
                marginTop: "2px",
                marginBottom: "6px",
                width: "525px",
              }}
            ></div>
          </>
        )}
        <div></div>
        <div
          style={{ marginBottom: "5px", fontSize: "10px", fontWeight: "600" }}
        >{`${invoice.data.countryCode} ${invoice.data.contactNumber}`}</div>
        <div
          style={{
            marginLeft: "330px",
            marginTop: "-20px",
            fontSize: "10px",
            fontWeight: "600",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <span style={{ color: "#7f7f7f" }}>Payment Gateway:</span>
            <span style={{ marginLeft: "10px" }}>
              {invoice.data.paymentMethod}
            </span>
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "3px" }}
          >
            <span style={{ color: "#7f7f7f" }}>Payment Mode:</span>
            <span style={{ marginLeft: "23px" }}>Online</span>
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "3px" }}
          >
            <span style={{ color: "#7f7f7f" }}>Transaction Time:</span>
            <span style={{ marginLeft: "12px" }}>{`${moment(
              invoice.data.createdAt
            )
              .tz(timeZoneName)
              .format("LT (z)")}`}</span>
          </div>
        </div>
        <div
          style={{ marginTop: "-30px", fontSize: "10px", fontWeight: "600" }}
        >
          {invoice.data.email}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "7px",
            fontSize: "10px",
            fontWeight: "600",
          }}
        >
          <span style={{ color: "#7f7f7f" }}>Transaction Date:</span>
          <span style={{ marginLeft: "16px" }}>
            {moment(invoice.data.createdAt).format("Do MMM YYYY")}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "4px",
            fontSize: "10px",
            fontWeight: "600",
          }}
        >
          <span style={{ color: "#7f7f7f" }}>Transaction ID:</span>
          <span style={{ marginLeft: "27px" }}>{invoice.data.paymentId}</span>
        </div>
      </div>
      <div
        style={{
          marginTop: "10px",
          marginLeft: "-25px",
          padding: "0",
          width: "525px",
          fontSize: "9.5px",
          fontWeight: "700",
        }}
      >
        <div
          style={{
            marginTop: "33px",
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#eef2f3",
            paddingTop: "8px",
            paddingBottom: "5px",
            paddingLeft: "4px",
          }}
        >
          <span style={{ fontFamily: "Roboto, sans-serif" }}>Sr.No.</span>
          <span
            style={{ marginLeft: "10px", fontFamily: "Roboto, sans-serif" }}
          >
            Product Name
          </span>
          <span
            style={{ marginLeft: "321px", fontFamily: "Roboto, sans-serif" }}
          >
            Total
          </span>
        </div>

        <div style={{ marginLeft: "12px", fontWeight: "600" }}>
          {invoice.data.courses &&
            invoice.data.courses.map((course, courseIndex) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 15 * courseIndex + "px",
                }}
              >
                <span style={{ width: "20px" }}>{courseIndex + 1}</span>
                <span style={{ marginLeft: "10px", width: "380px" }}>
                  {course.title}
                </span>
                <span style={{ marginLeft: "8px" }}>
                  {course.isInOffer ? course.offerPrice : course.crossedPrice}
                </span>
              </div>
            ))}
        </div>

        {invoice.data.installmentOption &&
          invoice.data.installmentOption.length > 0 && (
            <div style={{ marginLeft: "12px", fontWeight: "600" }}>
              {invoice.data.installmentOption.map((installment, index) => (
                <>
                  {installment !== null && installment.isPaid && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 15 * index + "px",
                      }}
                    >
                      <span style={{ width: "20px" }}></span>
                      <span style={{ marginLeft: "10px", width: "380px" }}>
                        Installment {index + 1}
                      </span>
                      <span style={{ marginLeft: "8px" }}>
                        {invoice.data.currencySymbol} {invoice.data.grandTotal}
                      </span>
                    </div>
                  )}
                </>
              ))}
            </div>
          )}

        <div
          style={{ marginTop: "20px", marginLeft: "280px", fontSize: "10px" }}
        >
          {invoice.data.offerDetails &&
            invoice.data.offerDetails.couponCode && (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    width: "100px",
                    textAlign: "right",
                    marginTop: "5px",
                  }}
                >
                  Coupon Code
                </span>
                <span style={{ marginLeft: "50px", marginTop: "5px" }}>
                  {invoice.data?.offerDetails?.couponCode}
                </span>
              </div>
            )}

          {invoice.data &&
          invoice.data.discountInNumbers &&
          invoice.data.discountInNumbers > 0 ? (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span
                style={{ width: "100px", textAlign: "right", marginTop: "5px" }}
              >
                Discount
              </span>
              <span style={{ marginLeft: "50px", marginTop: "5px" }}>
                -{invoice.data.currencySymbol}
                {Math.round(invoice.data?.discountInNumbers)}/-
              </span>
            </div>
          ) : null}

          {invoice.data &&
          invoice.data.gatewayDiscountInNumber &&
          invoice.data.gatewayDiscountInNumber > 0 ? (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span
                style={{ width: "100px", textAlign: "right", marginTop: "5px" }}
              >
                Gateway Discount
              </span>
              <span style={{ marginLeft: "50px", marginTop: "5px" }}>
                -{invoice.data.currencySymbol}
                {Math.round(invoice.data?.gatewayDiscountInNumber)}/-
              </span>
            </div>
          ) : null}

          <div style={{ display: "flex", flexDirection: "row" }}>
            <span
              style={{ width: "100px", textAlign: "right", marginTop: "5px" }}
            >
              <span style={{ fontFamily: "Roboto, sans-serif" }}>
                Total Amount
              </span>
            </span>
            <span style={{ marginLeft: "50px", marginTop: "5px" }}>
              <span>
                {invoice.data.currencySymbol} {invoice.data.netTotal}
              </span>
            </span>
          </div>

          {/* <div style={{ display: "flex", flexDirection: "row" }}>
            <span
              style={{
                marginTop: "10px",
                width: "100px",
                textAlign: "right",
                fontSize: "7px",
                color: "#7f7f7f",
              }}
            >
              (Tax inclusive, rounded off)
            </span>
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <span
              style={{
                width: "100px",
                textAlign: "right",
                marginTop: "1px",
                color: "#7f7f7f",
              }}
            >
              CGST 9%
            </span>
            <span
              style={{ marginLeft: "50px", marginTop: "1px", color: "#7f7f7f" }}
            >
              {invoice.data.currencySymbol}{" "}
              {Math.round((invoice.data.netTotal / 118) * 100 * (9 / 100))}
            </span>
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <span
              style={{
                width: "100px",
                textAlign: "right",
                marginTop: "1px",
                color: "#7f7f7f",
              }}
            >
              SGST 9%
            </span>
            <span
              style={{ marginLeft: "50px", marginTop: "1px", color: "#7f7f7f" }}
            >
              {invoice.data.currencySymbol}{" "}
              {Math.round((invoice.data.netTotal / 118) * 100 * (9 / 100))}
            </span>
          </div> */}
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "50px",
          left: "60px",
          width: "500px",
          textAlign: "center",
        }}
      >
        <div
          style={{
            padding: "0",
            borderBottom: "1pt solid #dddedd",
            width: "525px",
          }}
        ></div>
        <span style={{ marginTop: "5px", fontSize: "11px", fontWeight: "600" }}>
          eMediSkill Services LLP
        </span>
        <div
          style={{
            backgroundColor: "#e21e23",
            height: "15px",
            width: "200px",
            position: "absolute",
            bottom: "-56px",
            right: "60px",
          }}
        ></div>
        <div
          style={{
            backgroundColor: "#293d68",
            height: "15px",
            width: "134px",
            position: "absolute",
            bottom: "-56px",
            right: "-60px",
          }}
        ></div>
      </div>
    </div>
  );
};

export default InvoiceMobile;
