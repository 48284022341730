import React from "react";
import "./style.css";
import Logo from "@assets/invoice/hfdcInvoiceIcon.png";
import moment from "moment";

function HDFCInvoice({ data }) {
  var timeZoneName = moment.tz.guess();

  return (
    <div className="invoiceForm">
      <div className="d-flex">
        <div style={{ flex: 1 }}>
          <img src={Logo} height="75px" />
        </div>
        <div
          style={{
            flex: 1,
            fontSize: 30,
            fontWeight: 700,
            color: "#0f62ac",
            textAlign: "right",
            paddingTop: "30px",
          }}
        >
          INVOICE
        </div>
      </div>
      <div
        style={{
          width: "65%",
          gap: "8px",
          display: "flex",
          flexDirection: "column",
          marginTop: "10px",
        }}
      >
        <h3>CRESCENT INDIA MEDICAL EDUCATION TRUST</h3>
        <div>
          Inamdar Hospial Building, Survey No. 15, Fatima Nagar, Wanowrie, Pune
          41104. Maharashtra, India.
        </div>
        <div className="d-flex gap-2 flex-row">
          <b>GSTIN : </b>
          27AAATC8153A1Z3
        </div>
      </div>
      <div className="d-flex flex-row py-2 border-top gap-2 mt-1">
        <div style={{ flex: 0.52 }}>
          <b>BILLED TO</b>
          <div style={{ lineHeight: 1.5, marginTop: "7px" }}>
            {data.firstName} {data.lastName}
            <br></br>
            Country Name: {data.countryName}
            <br></br>
            Country Code + Mobile: {data.countryCode}
            {data.contactNumber}
          </div>
        </div>
        <div style={{ flex: 0.48 }}>
          <div className="d-flex flex-column gap-2 ">
            <div className="d-flex justify-content-between">
              <span>Invoice #</span>
              <span style={{ fontWeight: 700 }}>{data.invoiceId}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span>Invoice Date</span>
              <span style={{ fontWeight: 700 }}>
                {moment(data.createdAt).format("MMMM DD, YYYY")}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span>Invoice Amount</span>
              <span
                style={{ fontFamily: "Roboto, sans-serif", fontWeight: 700 }}
              >
                {data.orderAmount}
              </span>
            </div>
          </div>
          <div className="d-flex flex-column gap-2 pt-2 mt-2 border-top">
            <div className="d-flex justify-content-between">
              <span>Transaction Date</span>
              <span style={{ fontWeight: 700 }}>
                {moment(data.createdAt).format("DD-MM-YYYY")}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span>Transaction ID</span>
              <span style={{ fontWeight: 700, textWrap: "wrap" }}>
                {data.paymentId}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span>Payment Mode</span>
              <span style={{ fontWeight: 700 }}>{data.paymentMethod}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span>Transaction Time</span>
              <span style={{ fontWeight: 700 }}>{`${moment(data.createdAt)
                .tz(timeZoneName)
                .format("LT (z)")}`}</span>
            </div>
          </div>
        </div>
      </div>
      <table>
        <tr>
          <td className="border-bottom border-top">Description</td>
          <td className="border-bottom border-top">AMOUNT</td>
        </tr>
        {data.courses.map((course, index) => {
          return (
            <tr key={index}>
              <td style={{ fontWeight: "bold" }}>
                {index + 1}. {course.title}
              </td>
              <td style={{ fontFamily: "Roboto, sans-serif" }}>
                {course.isInOffer ? course.offerPrice : course.crossedPrice}
              </td>
            </tr>
          );
        })}

        {data.installmentOption &&
          data.installmentOption.length > 0 &&
          data.installmentOption.map((installment, index) => (
            <>
              {/* {console.log(data)} */}
              {installment !== null && installment.isPaid && (
                <tr key={index}>
                  <td style={{ textAlign: "right", fontWeight: "bold" }}>
                    Installment {index + 1}
                  </td>
                  {/* <td style={{ fontFamily: 'Roboto, sans-serif' }}>{data.currencySymbol} {data.grandTotal*installment.discount/100}/-</td> */}
                  <td style={{ fontFamily: "Roboto, sans-serif" }}>
                    {data.currencySymbol} {data.orderAmount}/-
                  </td>
                </tr>
              )}
            </>
          ))}

        {data.offerDetails && data.offerDetails.couponCode && (
          <tr>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              Coupon Code
            </td>
            <td>{data.offerDetails.couponCode}</td>
          </tr>
        )}

        {data.discountInNumbers && data.discountInNumbers > 0 && (
          <tr>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>Discount</td>
            <td style={{ fontFamily: "Roboto, sans-serif" }}>
              -{data.currencySymbol}
              {Math.round(data.discountInNumbers)}/-
            </td>
          </tr>
        )}

        {data &&
          data.gatewayDiscountInNumber &&
          data.gatewayDiscountInNumber > 0 && (
            <tr>
              <td style={{ textAlign: "right", fontWeight: "bold" }}>
                Gateway Discount
              </td>
              <td style={{ fontFamily: "Roboto, sans-serif" }}>
                -{data.currencySymbol}
                {Math.round(data.gatewayDiscountInNumber)}/-
              </td>
            </tr>
          )}

        <tr>
          <td
            className="border-top"
            style={{ textAlign: "right", fontWeight: "bold" }}
          >
            TOTAL
          </td>
          <td
            style={{ fontFamily: "Roboto, sans-serif" }}
            className="border-top"
          >
            {data.orderAmount}
          </td>
        </tr>
        {/* <tr>
                <td style={{ textAlign: "right", fontWeight: "bold" }}>AMOUNT DUE</td>
                <td style={{ fontFamily: 'Roboto, sans-serif' }}>{data.orderAmount}</td>
            </tr> */}
      </table>

      <div className="mt-auto gap-2 d-flex flex-column align-items-center">
        <h3 style={{ textAlign: "center" }}>Thank you for trusting us</h3>
        <div style={{ textAlign: "center", color: "grey" }}>
          For any queries related to thid document please contact us
        </div>
        <span
          style={{
            textAlign: "center",
            color: "#0f62ac",
            fontWeight: "bold",
            borderBottom: "2px solid #373435",
          }}
        >
          +91 9822081133
        </span>
      </div>
    </div>
  );
}

export default HDFCInvoice;
